import React from 'react'
import * as styles from './index.module.less'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import classnames from 'classnames'
import emailIcon from './img/emailIcon.png'
import addressIcon from './img/addressIcon.png'
import telIcon from './img/telIcon.png'

interface CompanyCardProps {
  customClass?: any
  title: string
  email: string
  tel: string
  address: string
  background?: any
}

const CompanyCard: React.FC<CompanyCardProps> = ({ customClass, title, email, tel, address, background }) => {
  const isMb = useIsWindowWidthSmaller()
  return (
    <div className={classnames(styles.CompanyCardWrapper, customClass)}>
      <div className="CompanyCard">
        <div className="CompanyCard-title">{title}</div>
        <div className="CompanyCard-textWrapper">
          <img src={emailIcon} alt="emailIcon" className="CompanyCard-icon" />
          <div className="CompanyCard-text">{`Email：${email}`}</div>
        </div>
        <div className="CompanyCard-textWrapper">
          <img src={telIcon} alt="telIcon" className="CompanyCard-icon" />
          <div className="CompanyCard-text">{`Tel：${tel}`}</div>
        </div>
        <div className="CompanyCard-textWrapper">
          <img src={addressIcon} alt="addressIcon" className="CompanyCard-icon" />
          <div className="CompanyCard-text">{`Address：${address}`}</div>
        </div>
      </div>
    </div>
  )
}

export default CompanyCard
