import React from 'react'
import * as styles from './index.module.less'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import classnames from 'classnames'

interface IconCardProps {
  customClass?: any
  icon: any
  title: string
  desc: string
}

const IconCard: React.FC<IconCardProps> = ({ customClass, icon, title, desc }) => {
  const isMb = useIsWindowWidthSmaller()
  return (
    <div className={classnames(styles.iconCardWrapper, customClass)}>
      <div className="iconCard">
        <img src={icon.publicURL ?? icon} alt="iconCard" className="iconCard-icon" />
        <div className="iconCard-right">
          <div className="iconCard-title">{title}</div>
          <div className="iconCard-desc">{desc}</div>
        </div>
      </div>
    </div>
  )
}

export default IconCard
