import React from 'react'
import * as styles from './index.module.less'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import classnames from 'classnames'

interface CardProps {
  customClass?: any
  title: string
  desc: string
}

const Card: React.FC<CardProps> = ({ customClass, title, desc }) => {
  const isMb = useIsWindowWidthSmaller()
  return (
    <div className={classnames(styles.aboutUsCardWrapper, customClass)}>
      <div className="aboutUsCard">
        <div className="aboutUsCard-title">{title}</div>
        <div className="aboutUsCard-desc">{desc}</div>
      </div>
    </div>
  )
}

export default Card
