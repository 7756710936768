import React from 'react'
import * as styles from './index.module.less'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import classnames from 'classnames'

interface ListCardProps {
  customClass?: any
  img: any
  descList: string[]
}

const ListCard: React.FC<ListCardProps> = ({ customClass, img, descList }) => {
  const isMb = useIsWindowWidthSmaller()
  return (
    <div className={classnames(styles.listCardWrapper, customClass)}>
      <div className="listCard">
        <img src={img.publicURL ?? img} alt="ListCardIcon" className="listCardIcon" />
        <div className="listCardDesc">
          {descList.map((item, index) => {
            return (
              <div className="listCardDescItem">
                <div className="listCardDescItemIcon">
                  <div></div>
                </div>
                <div className="listCardDescItemText">{item}</div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default ListCard
