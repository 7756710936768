import React from 'react'
import * as styles from './index.module.less'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import classnames from 'classnames'

interface BannerProps {
  title: string
  desc: string
  img: any
  customClass?: any
}

const Banner: React.FC<BannerProps> = ({ title, desc, img, customClass }) => {
  const isMb = useIsWindowWidthSmaller()
  return (
    <div className={classnames(styles.aboutUsBannerWrapper, customClass)}>
      <div className="aboutUsBannerContent">
        <div className="aboutUsBannerContent-title">{title}</div>
        <div className="aboutUsBannerContent-desc">{desc}</div>
        <img src={img.publicURL ?? img} alt="aboutUsBannerImg" className="aboutUsBannerImg" />
      </div>
    </div>
  )
}

export default Banner
