import React from 'react'
import * as styles from './index.module.less'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import classnames from 'classnames'

interface InternationalTitleProps {
  /**标题 */
  title: string
  customClass?: React.CSSProperties
}

const InternationalTitle: React.FC<InternationalTitleProps> = ({ title, customClass }) => {
  return <div className={classnames(styles.internationalTitleWrapper, customClass)}>{title}</div>
}

export default InternationalTitle
