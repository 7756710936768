import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'layouts/en'
import Seo from 'components/EN/Seo'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import Banner from 'components/NewEnAboutUs/Banner'
import InternationalTitle from 'components/InternationalTitle'
import Card from 'components/NewEnAboutUs/Card'
import ListCard from 'components/NewEnAboutUs/ListCard'
import IconCard from 'components/NewEnAboutUs/IconCard'
import CompanyCard from 'components/NewEnAboutUs/CompanyCard'
import * as styles from './index.module.less'

const AboutUs = (props) => {
  const { data } = props
  const { newAboutUsYaml } = data
  const { part1, part2, part3, part4, part5, part6 } = newAboutUsYaml

  const isMb = useIsWindowWidthSmaller()

  return (
    <Layout {...props}>
      <Seo
        title="Sensors Data | About Us"
        description="Sensors Data is driving the era of digital customer operation and management in global markets. We use cutting-edge technology and innovation to help businesses optimize customer journeys, unlock actionable insights, and thrive in the digital era"
        keywords="Sensors Data, Sensorsdata, Sensorsdata investment, Sensorsdata introduction"
      />
      <div className={styles.newEnAboutUsWrapper}>
        <div className={styles.banner}>
          <Banner {...part1} />
        </div>
        <div className={styles.part2Title}>
          <InternationalTitle title={isMb ? part2.mbTitle : part2.title} customClass={styles.customTitleClass} />
        </div>
        <div className={styles.part2Card}>
          {part2.cardContent.map((item, index) => {
            return (
              <div key={item.title + index} className={styles.part2CardItem}>
                <Card {...item} />
              </div>
            )
          })}
        </div>
        <div className={styles.part3}>
          <div className={styles.part3Title}>
            <InternationalTitle title={isMb ? part3.mbTitle : part3.title} customClass={styles.part3TitleText} />
          </div>
          <div className={styles.part3CardWrapper}>
            <div className={styles.part3Card}>
              {part3.listCardContent.map((item, index) => {
                return (
                  <div key={index} className={styles.part3CardItem}>
                    <ListCard {...item} />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className={styles.part4}>
          <div className={styles.part4Title}>
            <InternationalTitle title={part4.title} customClass={styles.customTitleClass} />
          </div>
          <div className={styles.part4Content}>
            <img src={part4.img.publicURL} alt="part4Img" className={styles.part4Img} />
            <div className={styles.rightContent}>
              {part4.iconCard.map((item, index) => {
                return (
                  <div key={item.title + index} className={styles.iconCard}>
                    <IconCard {...item} />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className={styles.part5}>
          <div className={styles.part5Title}>
            <InternationalTitle title={part5.title} customClass={styles.customTitleClass} />
          </div>
          <div className={styles.part5Content}>
            <CompanyCard {...part5.CompanyCard} />
          </div>
        </div>
        <div className={styles.part6}>
          <div className={styles.part6Title}>
            <InternationalTitle title={part6.title} customClass={styles.customTitleClass} />
          </div>
          <div className={styles.part6Content}>
            <img
              src={isMb ? part6.firstLineImgMb.publicURL : part6.firstLineImg.publicURL}
              alt="image"
              className={styles.part6Img1}
            />
            <img
              src={isMb ? part6.secondLineImgMb.publicURL : part6.secondLineImg.publicURL}
              alt="image"
              className={styles.part6Img2}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    newAboutUsYaml {
      part1 {
        title
        desc
        img {
          publicURL
        }
      }
      part2 {
        title
        mbTitle
        cardContent {
          title
          desc
        }
      }
      part3 {
        title
        mbTitle
        listCardContent {
          img {
            publicURL
          }
          descList
        }
      }
      part4 {
        title
        img {
          publicURL
        }
        iconCard {
          icon {
            publicURL
          }
          title
          desc
        }
      }
      part5 {
        title
        CompanyCard {
          title
          email
          tel
          address
        }
      }
      part6 {
        title
        firstLineImg {
          publicURL
        }
        secondLineImg {
          publicURL
        }
        firstLineImgMb {
          publicURL
        }
        secondLineImgMb {
          publicURL
        }
      }
    }
  }
`

export default AboutUs
